import BodyPortal from "@/components/shared/BodyPortal";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { colors } from "@/styles/global.styles";
import { blockContact, unblockContact } from "@/utils/helpers/fetchContacts";
import InfoIcon from "@mui/icons-material/Info";
import { AnimatePresence } from "motion/react";
import { ReactNode, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  clearBlockedContactsCache,
  isPhoneNumberBlocked,
} from "../contacts/contactUtils";
import WebGwContact from "../helpers/WebGwContact";
import { formatPhoneNumber } from "../helpers/formatPhoneNumber";

type ModalType = "calling" | "messaging" | "default";

export function useBlock({
  contact,
  forceBlock = false,
  onContactBlocked,
  refreshDependencies = [],
  runCheck = true,
}: {
  contact?: WebGwContact | null;
  forceBlock?: boolean;
  onContactBlocked?: (blocked: boolean) => void;
  refreshDependencies?: any[];
  runCheck?: boolean;
}): UseBlock {
  const [blockModalOpen, setBlockModalOpen] = useState({
    type: "default",
    open: false,
  });
  const [isBlocked, setIsBlocked] = useState<boolean>(forceBlock);
  const blockPrefix = isBlocked ? "un" : "";
  const errorBlockingContactToast = () =>
    toast.error("Error " + blockPrefix + "blocking the contact.", {
      icon: <InfoIcon />,
      duration: 5000,
      position: "bottom-right",
      style: {
        backgroundColor: "#2E3237",
        color: colors.primaryAccentColor,
      },
    });

  if (forceBlock !== isBlocked) {
    setIsBlocked(forceBlock);
  }

  useEffect(() => {
    if (!runCheck) {
      return;
    }

    void checkIfBlocked();
  }, [contact?.getMainPhoneNumber(), ...refreshDependencies]);

  const checkIfBlocked = async () => {
    if (!contact) {
      return Promise.resolve(false);
    }

    const fetchData = async () => {
      const blocked = await isPhoneNumberBlocked(contact.getMainPhoneNumber());
      setIsBlocked(blocked);
      onContactBlocked?.(blocked);
      return blocked;
    };

    return await fetchData();
  };

  const showBlockModal = (type?: ModalType) => {
    setBlockModalOpen({ type: type || "default", open: true });
  };

  const handleBlockOk = async () => {
    if (!contact || !contact.getMainPhoneNumber()) {
      errorBlockingContactToast();
      handleCloseBlockModal();
      return;
    }

    const mainPhoneNumber = contact.getMainPhoneNumber();
    const sipFormatted = await formatPhoneNumber(mainPhoneNumber, "SIP");
    const success = await (isBlocked
      ? unblockContact(sipFormatted)
      : blockContact(sipFormatted));

    if (success) {
      await clearBlockedContactsCache();
      setIsBlocked(!isBlocked);
      onContactBlocked?.(!isBlocked);
      toast("Contact has been " + blockPrefix + "blocked!", {
        duration: 5000,
        position: "bottom-right",
        style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
      });
    } else {
      toast.error(
        "Error " +
          blockPrefix +
          "blocking the contact. Please check if the number you are trying to block is valid.",
        {
          icon: <InfoIcon />,
          duration: 5000,
          position: "bottom-right",
          style: {
            backgroundColor: "#2E3237",
            color: colors.primaryAccentColor,
          },
        }
      );
    }

    handleCloseBlockModal();
  };

  const handleCloseBlockModal = () => {
    setBlockModalOpen({ type: "default", open: false });
  };

  const blockModal = (
    <AnimatePresence>
      <BodyPortal>
        {blockModalOpen.open && (
          <ConfirmationPopup
            title={
              isBlocked
                ? blockModalOpen.type !== "default"
                  ? "Blocked contact"
                  : "Unblock Contact"
                : "Block Contact"
            }
            confirmationMessage={
              blockModalOpen.type !== "default" && isBlocked
                ? `Cannot ${blockModalOpen.type === "calling" ? "place call" : "send message"} because the contact is blocked. Would you like to unblock contact?`
                : `Are you sure you want to ${
                    isBlocked ? "unblock" : "block"
                  } this contact?`
            }
            togglePopup={handleCloseBlockModal}
            handleAction={handleBlockOk}
            primaryButtonText={isBlocked ? "Unblock" : "Block"}
          />
        )}
      </BodyPortal>
    </AnimatePresence>
  );

  return {
    value: isBlocked ? "Unblock" : "Block",
    modal: blockModal,
    check: checkIfBlocked,
    show: showBlockModal,
  };
}

export type UseBlock = {
  value: string;
  modal: ReactNode;
  check: () => Promise<boolean>;
  show: (type?: ModalType) => void;
};
